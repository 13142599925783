import React from "react";
import "./Programs.css";

//import Star from "./images/garden.jpg";
import Landscape from "../images/program.jpg";
//import Maize from "./images/maize.jpg";
//import Spinach from "./images/spinach.jpg";
function Programs() {
  return (
    <div>
     
      <div className="programsPage">
            <div className="programs-container">
                <div className="programs">
                    <h1 id="currprograms">Current Programs</h1>
                </div>
            </div>
        <div className="boxes">
          <div className="garden">
            <div className="gardenimage">
              <img src={Landscape} alt="" id="gardenimage" />
            </div>
            <div className="gardentext">
              <h2>RAIL RegenAG Lab Series</h2>
              <p> Hands-on workshops advancing regenerative agriculture and sustainable practices.</p>
              <button id="gardenread">Read More</button>
            </div>
          </div>
          <div className="garden">
            <div className="gardenimage">
              <img src={Landscape} alt="" id="gardenimage" />
            </div>
            <div className="gardentext">
              <h2>
               HAWA Community Run
              </h2>
              <p>
              Community Run is a dynamic event promoting health and wellness through community engagement and physical activity.
              </p>
              <button id="gardenread2">Read More</button>
            </div>
          </div>
          <div className="garden">
            <div className="gardenimage">
              <img src={Landscape} alt="" id="gardenimage" />
            </div>
            <div className="gardentext">
              <h2>WIRAH HomesteadHer</h2>
              <p>
              Empowering women in regenerative agriculture through education and resources.
              </p>
              <button id="gardenread3">Read More</button>
            </div>
          </div>
        </div>
        <div className="boxes">
          <div className="garden">
            <div className="gardenimage">
              <img src={Landscape} alt="" id="gardenimage" />
            </div>
            <div className="gardentext">
              <h2>K&ST ExpoSim</h2>
              <p> Showcasing innovative solutions for knowledge and skill transfer.</p>
              <button id="gardenread">Read More</button>
            </div>
          </div>
          <div className="garden">
            <div className="gardenimage">
              <img src={Landscape} alt="" id="gardenimage" />
            </div>
            <div className="gardentext">
              <h2>
              NuBAP EnlightenedU
              </h2>
              <p>
              Nourishing minds through nutrition education and wellness initiatives.</p>
              <button id="gardenread2">Read More</button>
            </div>
          </div>
          <div className="garden">
            <div className="gardenimage">
              <img src={Landscape} alt="" id="gardenimage" />
            </div>
            <div className="gardentext">
              <h2>Farmasee Econ</h2>
              <p>
              Promoting local consumption to strengthen community agricultural economies.
              </p>
              <button id="gardenread3">Read More</button>
            </div>
          </div>
        </div>
        <div className="boxes">
          <div className="garden">
            <div className="gardenimage">
              <img src={Landscape} alt="" id="gardenimage" />
            </div>
            <div className="gardentext">
              <h2>ABMTS VentureDev</h2>
              <p> Fostering venture development through alumni mentorship and support.</p>
              <button id="gardenread">Read More</button>
            </div>
          </div>
          <div className="garden">
            <div className="gardenimage">
              <img src={Landscape} alt="" id="gardenimage" />
            </div>
            <div className="gardentext">
              <h2>
              NMRS Industry Visits
              </h2>
              <p>
              Connecting members with industry leaders through informative visits.</p>
              <button id="gardenread2">Read More</button>
            </div>
          </div>
          <div className="garden">
            <div className="gardenimage">
              <img src={Landscape} alt="" id="gardenimage" />
            </div>
            <div className="gardentext">
              <h2>NMRS RegenMe Challenge</h2>
              <p>
              Promoting healthy living through a member challenge initiative.</p>
              <button id="gardenread3">Read More</button>
            </div>
          </div>
        </div>
        <div className="pagination">
                <button>1</button>
                <button>2</button>
                <button>3</button>
                <button>4</button>
            </div>
      </div>
      <br />
      
      </div>
  );
}

export default Programs;
